import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Fab from '@material-ui/core/Fab';
import Button from '@material-ui/core/Button'
import ArrowFowardIosIcon from '@material-ui/icons/ArrowForwardIos';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import ContentLink from './contentlink';
import LinkButton from '../components/linkbutton';
import './components.scss';

const useStyles = makeStyles(theme =>({
    root: {
        padding: '0 30px',
        '& > *': {
            margin: theme.spacing(1),
        }
    },
    fab: {
        backgroundColor: "#48bd96",
        color: "#ffe066",
        '&:hover': {
            backgroundColor: "#ffe066",
            color: "#48bd96",
        }
    },
    msButton: {
        marginRight: theme.spacing(1),
        backgroundColor: '#48bd96',
        color: '#ffffff',
        '&:hover': {
            backgroundColor: '#30a679',
        },
    },
}));

const Contents = ({ contents, language }) => {
    const classes = useStyles();
    let output;
    let paragraphsArray = [];
    let linkUrl =[];
    let linkTitle =[];
    let contentType;
    contents.map(content => {
        if ('paragraph' === content.type) {
            content.paragraphs.map(paragraph => {
                paragraphsArray.push(paragraph);
            });
            contentType = 'paragraph';
        } else if ('link' === content.type) {
            if ('ja' !== language) {
                linkUrl.push('/'+language+content.link.url);
                linkTitle.push(content.link.title);
            } else {
                linkUrl.push(content.link.url);
                linkTitle.push(content.link.title);
            }
            contentType = 'link';
        } else if ('external' === content.type) {
            linkUrl.push(content.link.url);
            linkTitle.push(content.link.title);
            contentType = 'external';
        } else if ('image' === content.type)  {
            
        } else {

        }
    });
    // return (<div className={classes.root} dangerouslySetInnerHTML={{__html: output}}></div>)
    // return (<div className={classes.root}>{output2}</div>);
    // return <pre>{JSON.stringify(contentType, null, 4)}</pre>
    if ('paragraph' === contentType) {
        return (
            <div className={classes.root}>{
               paragraphsArray.map(paragraph => ( <p>{paragraph}</p>))
            }</div>
        )
    } else if ('link' === contentType) {
        return (
            <div className={classes.root}>{
               linkUrl.map((url, i) => (
                <React.Fragment>
                    {/* <Fab variant="extended" href={url} className={classes.fab}><ArrowFowardIosIcon />{linkTitle[i]}</Fab> */}
                    {/* <Button
                        href={url}
                        variant="contained"
                        endIcon={<KeyboardArrowRight />}
                        className={classes.msButton}
                    >{linkTitle[i]}</Button> */}
                    <LinkButton url={url} title={linkTitle[i]} type="internal" />
                    <br display={{xs: 'block', sm: 'none'}} />
                </React.Fragment>
               ))
            }</div>
            // <pre>{JSON.stringify(contentType, null, 4)}</pre>
        )
    } else if ('external' === contentType) {
        return (
            <div className={classes.root}>{
               linkUrl.map((url, i) => (
                <React.Fragment>
                    {/* <Fab variant="extended" href={url} className={classes.fab}><ArrowFowardIosIcon />{linkTitle[i]}</Fab> */}
                    {/* <Button
                        href={url}
                        variant="contained"
                        endIcon={<KeyboardArrowRight />}
                        className={classes.msButton}
                    >{linkTitle[i]}</Button> */}
                    <LinkButton url={url} title={linkTitle[i]} type="external" />
                </React.Fragment>
               ))
            }</div>
            // <pre>{JSON.stringify(contentType, null, 4)}</pre>
        )
    } else {
        return (
            <div className={classes.root}>{
               paragraphsArray.map(paragraph => ( <p>{paragraph}else</p>))
            }</div>
        )
    }
        
}

Contents.propTypes = {
    contents: PropTypes.array,
    language: PropTypes.string,
}

export default Contents