import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import scrollTo from 'gatsby-plugin-smoothscroll';
import Fab from '@material-ui/core/Fab';
import Button from '@material-ui/core/Button';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import ArrowFowardIosIcon from '@material-ui/icons/ArrowForwardIos';
import ExitToIcon from '@material-ui/icons/ExitToApp';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';

const useStyles = makeStyles(theme => ({
    root: {

    },
    internal: {
        marginRight: theme.spacing(1),
        backgroundColor: '#48bd96',
        color: '#ffffff',
        '&:hover': {
            backgroundColor: '#30a679',
        },
    },
    external: {
        marginRight: theme.spacing(1),
        color: '#48bd96',
        borderColor: '#48bd96',
        '&:hover': {
            color: '#ffe066',
            borderColor: '#ffe066',
            backgroundColor: '#ffffff',
        },
    },
    anchor: {
        marginRight: theme.spacing(1),
        backgroundColor: '#48bd96',
        color: '#ffffff',
        '&:hover': {
            backgroundColor: '#30a679',
        },
    },
    fab: {
        backgroundColor: "#48bd96",
        color: "#ffe066",
        '&:hover': {
            backgroundColor: "#ffe066",
            color: "#48bd96",
        },
    }
}));

const LinkButton = ({ url, title, type}) => {
    const classes = useStyles();
    const isFab = false;

    if (isFab) {
        if ('internal' === type) {
            return (
                <Fab variant="extended" href={url} className={classes.fab}><ArrowFowardIosIcon />{title}</Fab>
            );
        } else if ('external' === type) {
            return (
                <Fab variant="extended" href={url} className={classes.fab}><ExitToIcon />{title}</Fab>
            );
        } else if ('anchor' === type) {
            return (
                <Fab variant="extended" href={url} className={classes.fab}><ArrowDownwardIcon />{title}</Fab>
            );
        } else {
            return null;
        }
    } else {
        if ('internal' === type) {
            return (
                <Button
                    href={url}
                    variant="contained"
                    endIcon={<KeyboardArrowRight />}
                    className={classes.internal}
                >{title}</Button>
            );
        } else if ('external' === type) {
            return (
                <Button
                    href={url}
                    variant="outlined"
                    endIcon={<ExitToIcon />}
                    className={classes.external}
                >{title}</Button>
            );
        } else if ('anchor' === type) {
            const anchor = '#' + url;
            return (
                <Button
                    variant="contained"
                    endIcon={<ArrowDownwardIcon />}
                    className={classes.anchor}
                    onClick={() => scrollTo(anchor)}
                >{title}</Button>
            );
        } else {
            return null;
        }
    }
};

LinkButton.propTypes = {
    url: PropTypes.string,
    type: PropTypes.string,
    title: PropTypes.string,
};

export default LinkButton;
